import React, { useRef } from 'react';
import "./coupon-style.css";
import html2canvas from 'html2canvas';

const CouponCard = (
    { nameCoupon,
        prefixCoupon,
        numberCoupon,
        codeCoupon,
        bgColor,
        discount,
        discountValue,
        dateBegin,
        dateEnd,
        description,
        sundayChecked,
        mondayChecked,
        tuesdayChecked,
        wednesdayChecked,
        thursdayChecked,
        fridayChecked,
        saturdayChecked,
        selectedImage,
        urlImage,
        branchesSelected,
        validForAllBranchesChecked,
    }) => {
    // console.log('tuesdayChecked');
    // console.log(tuesdayChecked);
    const couponRef = useRef(null);

    const exportAsImage = () => {
        if (couponRef.current === null) return;

        html2canvas(couponRef.current, {
            useCORS: true,
            allowTaint: true,
            scale: 2, // Higher scale for better quality
        })
            .then((canvas) => {
                const dataUrl = canvas.toDataURL('image/png');
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = 'exported-content.png';
                link.click();
            })
            .catch((error) => {
                console.error('Error capturing content as image:', error);
            });
    };
    // console.log('urlImage');
    // console.log(urlImage);
    return (
        <div className="max-w-lg mx-auto my-8"            >
            <div ref={couponRef} className="relative"
                style={{ backgroundColor: bgColor }}
            >
                {selectedImage !== null && urlImage === null && urlImage !== "" && (
                    <div className="mt-4">
                        <img src={selectedImage} alt="Preview" className="w-full rounded-md" crossOrigin="anonymous" />
                    </div>
                )}
                {selectedImage === null && urlImage !== null && urlImage !== "" && (
                    <div className="mt-4">
                        <img src={urlImage} alt="Preview" className="w-full rounded-md" crossOrigin="anonymous" />
                    </div>
                )}
                {/* Coupon Container */}
                <div className={`relative  text-white rounded-lg p-8 items-center shadow-lg`}>
                    {/* <> */}
                    {/* Left side with jagged edges */}
                    <div className="absolute left-0 top-0 bottom-0 w-12 overflow-hidden">
                        <div className="h-full bg-red-600 clip-jagged-left"></div>
                    </div>

                    {/* Right Content */}
                    <div className="text-center w-full">
                        <div className="text-sm">{prefixCoupon}{numberCoupon}{codeCoupon}</div>
                        <h2 className="text-4xl font-bold">{nameCoupon}</h2>
                        <h2 className="text-4xl font-bold my-4">{discountValue}{discount} OFF</h2>
                        <p className="text-sm mb-4">
                            {description}
                        </p>
                        <p className="text-md font-semibold">Valido entre {dateBegin} - {dateEnd}</p>
                        <p className="text-xs font-semibold">Valido para los días: {sundayChecked === true ? "Domingo" : ""} - {mondayChecked === true ? "Lunes" : ""} - {tuesdayChecked === true ? "Martes" : ""} - {wednesdayChecked === true ? "Miercoles" : ""} - {thursdayChecked === true ? "Jueves" : ""} - {fridayChecked === true ? "Viernes" : ""} - {saturdayChecked === true ? "Sábado" : ""}</p>
                    </div>

                    {/* Right side with jagged edges */}
                    <div className="absolute right-0 top-0 bottom-0 w-12 overflow-hidden">
                        <div className="h-full bg-red-600 clip-jagged-right"></div>
                    </div>
                    {/* </> */}
                    {validForAllBranchesChecked === false &&
                        <div className='pt-2'>
                            <label>Valido para sucursal(s):</label>
                            {branchesSelected.map((item) => {

                                return (
                                    <li key={item.id} className="">
                                        {item.label}
                                    </li>
                                );

                            })}
                        </div>
                    }
                    {validForAllBranchesChecked === true &&
                        <div className='pt-2'>
                            <label>Valido para todas sucursales</label>
                        </div>
                    }
                </div>
            </div>
            {/* Button to Download Image */}
            <button onClick={exportAsImage} className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md">
                Descargar Imagen
            </button>
        </div>

    );
};

export default CouponCard;
