import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// import Modulo from "../../components/modulo";
// import { getModulos } from "./modulos-configuracion-general";
import "../../style/style-cargando.css";
import sinAccesoCard from "../../components/sin-acceso-card";
import cargandoCard from "../../components/cargando-card";
import Grid from "../../components/grid";
import { ESTILO_BOTON_LISTAR_NUEVO } from "../../utils/const/estilos-const";
import { sharedNextPage } from "../../utils/shared-functions/sharedFunctions";
import moment from "moment";
import { changeStatusCoupon, cloneCoupon, darBajaCoupon, getListCoupons, restaurarCoupon } from "../../api/api-coupon";
import { PERCENTAGE } from "../../utils/const/string-const";
import ModalConfirmar from "../../components/modal-confirmar";
import ModalChangeCouponStus from "../../components/modal-change-coupon-status";
import { getListConcept } from "../../api/api-concept";
import Select from "react-select";
import { INT_STATUS_ACTIVO, INT_STATUS_BAJA, INT_STATUS_DESACTIVADO } from "../../utils/const/int-const";
import { RefreshCcw } from "feather-icons-react/build/IconComponents";
import ModalSeeCoupon from "../../components/modal-see-coupon";

export default function ManageCoupons() {

    const history = useHistory();
    const [cargandoPage, setCargandoPage] = useState(false);
    const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(true);
    const [rows, setRows] = useState([]);
    const [rowSelected, setRowSelected] = useState(null);
    const [showModalDarBaja, setShowModalDarBaja] = useState(false);
    const [showModalClone, setShowModalClone] = useState(false);

    const [showModalStatus, setShowModalStatus] = useState(false);
    const [listStatus, setListStatus] = useState([]);
    const [statusSelected, setStatusSelected] = useState(null);
    const [statusToSearchSelected, setStatusToSearchSelected] = useState(null);

    const [showModalRestaurar, setShowModalRestaurar] = useState(false);

    const [showModalSeeCoupon, setShowModalSeeCoupon] = useState(false);

    const headers = [
        { key: "acciones", title: "ACCIONES" },
        { key: "id", title: "ID", visible: false, },
        { key: "idStatus", title: "idStatus", visible: false, filter: true, editLabel: true, },
        { key: "status", title: "ESTADO", visible: true, filter: true, },
        { key: "validoParaSucursales", title: "VALIDO PARA SUCURSALES", visible: false, filter: true },
        { key: "validoParaSucursalesLabel", title: "VALIDO PARA TODAS SUCURSALES", visible: true, filter: true },
        { key: "idAbbreviation", title: "idAbbreviation", visible: false, filter: true, editLabel: true, },
        { key: "abbreviation", title: "abbreviation", visible: false, filter: true, },
        { key: "idCurrency", title: "idCurrency", visible: false, filter: true, },
        { key: "currency", title: "MONEDA", visible: true, filter: true, },
        { key: "abbreviationCurrency", title: "ABBREVIATION MONEDA", visible: false, filter: true, },
        { key: "idTypeDiscount", title: "idTypeDiscount", visible: false, filter: true, },
        { key: "typeDiscount", title: "TIPO DESCUENTO", visible: true, filter: true, },
        { key: "description", title: "DESCRIPCION", visible: true, filter: true, },
        { key: "prefixCode", title: "prefixCode", visible: false, filter: true, },
        { key: "numberCoupon", title: "numberCoupon", visible: false, filter: true, },
        { key: "codeCoupon", title: "codeCoupon", visible: false, filter: true, },
        { key: "fullCodeCoupon", title: "CODIGO COUPON", visible: true, filter: true, },
        { key: "nameCoupon", title: "NOMBRE COUPON", visible: true, filter: true, },
        { key: "valueDiscount", title: "VALOR DESCUENTO", visible: true, filter: true, },
        { key: "idAppyDiscountTo", title: "idAppyDiscountTo", visible: false, filter: true, },
        { key: "applyDiscountTo", title: "APLICAR DESCUENTO A", visible: true, filter: true, },
        { key: "dateBeginLabel", title: "FECHA INICIO", visible: true, filter: true, },
        { key: "dateEndLabel", title: "FECHA FIN", visible: true, filter: true, },
        { key: "dateBegin", title: "FECHA INICIO", visible: false, filter: true, },
        { key: "dateEnd", title: "FECHA FIN", visible: false, filter: true, },
        { key: "sundayChecked", title: "DOMINGO", visible: false, filter: true, },
        { key: "mondayChecked", title: "LUNES", visible: false, filter: true, },
        { key: "tuesdayChecked", title: "MARTES", visible: false, filter: true, },
        { key: "wednesdayChecked", title: "MIERCOLES", visible: false, filter: true, },
        { key: "thursdayChecked", title: "JUEVES", visible: false, filter: true, },
        { key: "fridayChecked", title: "VIERNES", visible: false, filter: true, },
        { key: "saturdayChecked", title: "SABADO", visible: false, filter: true, },
        { key: "sundayLabel", title: "DOMINGO", visible: true, filter: true, },
        { key: "mondayLabel", title: "LUNES", visible: true, filter: true, },
        { key: "tuesdayLabel", title: "MARTES", visible: true, filter: true, },
        { key: "wednesdayLabel", title: "MIERCOLES", visible: true, filter: true, },
        { key: "thursdayLabel", title: "JUEVES", visible: true, filter: true, },
        { key: "fridayLabel", title: "VIERNES", visible: true, filter: true, },
        { key: "saturdayLabel", title: "SABADO", visible: true, filter: true, },
        { key: "limitUse", title: "LIMITAR USO", visible: false, filter: true, },
        { key: "limitUseLabel", title: "LIMITAR USO", visible: true, filter: true, },
        { key: "valueLimitUse", title: "VALOR LIMITE USO", visible: true, filter: true, },
        { key: "limitOnePerUser", title: "LIMITE UNO POR USUARIO", visible: false, filter: true, },
        { key: "limitOnePerUserLabel", title: "LIMITE UNO POR USUARIO", visible: true, filter: true, },
        { key: "dateUpdated", title: "FECHA PROCESO", visible: true, filter: true },
        { key: "timezone", title: "TIME ZONE", visible: false, filter: true },
        { key: "offset", title: "OFFSET", visible: false, filter: true },
        { key: "urlImage", title: "URL IMAGE", visible: false, filter: true },
        { key: "validoParaSucursales", title: "VALIDO PARA SUCURSALES", visible: false, filter: true },
    ];

    useEffect(() => {
        iniciar();

        // Set up the interval to run every 15 minutes (900,000 ms)
        const intervalId = setInterval(() => {
            iniciar();
        }, 1000 * 60 * 15); // 15 minutes

        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);

    const iniciar = async () => {
        try {
            /*let objPermiso = {};
            objPermiso.idMenuPrimerNivel = INT_ID_MODULO_CONFIGURACION;
            objPermiso.idMenuSegundoNivel = 0;
            objPermiso.idMenuTercerNivel = 0;
            let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(objPermiso);
            // console.log('obtenerPermisos');
            // console.log(obtenerPermisos);
            let permiso = obtenerPermisos[0];
            setPermisos(permiso);
            // console.log('idTipoUsuario');
            // console.log(idTipoUsuario);
            // debugger;
            if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
                setTieneAccesoAlModulo(true);
            }
            if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
                if (permiso.accesoAlModulo === TRUE) {
                    setTieneAccesoAlModulo(true);
                }
            }*/
            let objSend = {};
            objSend.status = statusToSearchSelected !== null ? statusToSearchSelected.value : null;
            const listCoupon = await getListCoupons(objSend);
            agregarAccionesALista(listCoupon, null);
            const listStats = await getListConcept({ group: 3 }, true);
            setListStatus(listStats);
            setCargandoPage(false);
        } catch (err) {
            console.log('iniciar err');
            console.log(err);
            setCargandoPage(false);
        }
    }

    const agregarAccionesALista = (lista, permiso) => {
        try {
            // console.log('agregarAccionesALista');
            // console.log(lista);
            if (lista.length === 0) {
                setRows(lista);
            }
            if (lista.length > 0) {
                const objModificar = {
                    label: "Modificar",
                    color: "green",
                    onClick: (param) => editElementSelected(param),
                };
                const objDarBaja = {
                    label: "Dar Baja",
                    color: "red",
                    onClick: (param) => onClickDarBaja(param),
                };
                const objRestaurar = {
                    label: "Restaurar",
                    color: "darkmagenta",
                    onClick: (param) => onClickRestaurar(param),
                };
                const objChangeStatus = {
                    label: "Cambiar Estado",
                    color: "blue",
                    onClick: (param) => onClickChangeStatus(param),
                };
                const objClone = {
                    label: "Clonar",
                    color: "indigo",
                    onClick: (param) => onClickhandleCloneCoupon(param),
                };
                const objVer = {
                    label: "Ver",
                    color: "teal",
                    onClick: (param) => onClickSeeCoupon(param),
                };
                // if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
                // if (permiso.nuevo === TRUE) {
                // accesoBotonNuevo = '';
                // }
                // }
                for (let i = 0; i < lista.length; i++) {
                    const coupon = lista[i];
                    let acciones = [];
                    // if (coupon.idStatus !== INT_STATUS_ACTIVO) {
                    acciones.push(objModificar);
                    // }
                    if (coupon.idStatus !== INT_STATUS_BAJA) {
                        acciones.push(objDarBaja);
                    }
                    if (coupon.idStatus === INT_STATUS_BAJA) {
                        acciones.push(objRestaurar);
                    }
                    // if (coupon.idStatus !== INT_STATUS_ACTIVO) {
                    acciones.push(objChangeStatus);
                    // }
                    acciones.push(objClone);
                    acciones.push(objVer);
                    // if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
                    // if (permiso.modificar === TRUE) {
                    // acciones.push(objModificar);
                    // }
                    // if (permiso.eliminarDeshabilitar === TRUE) {
                    // acciones.push(objDeshabilitar);
                    // }
                    // }
                    lista[i].acciones = acciones;
                }
                setRows(lista);
            }
        } catch (err) {
            console.log('agregarAccionesALista err');
            console.log(err);
        }
    }

    const handleInputChange = (event) => {
        // console.log('handleInputChange');
        const { name, value } = event.target;
        // console.log(name);
        // console.log(value);
        if (rowSelected !== null) {
            setRowSelected({ ...rowSelected, [name]: value });
        }
    };

    const handleNuevoClick = () => {
        console.log('handleNuevoClick');
        let obj = {};
        let objParams = null;
        let currentDate = moment();
        if (rowSelected !== null) {
            objParams = { ...rowSelected };
            if (objParams.acciones !== undefined) {
                delete objParams.acciones;
            }
        }
        if (objParams === null) {
            objParams = {};
            objParams.color = "#44C667";
            objParams.discountValue = 10;
            objParams.discountValuePlaceHolder = 10;
            objParams.couponCode = "";
            objParams.couponName = "";
            objParams.couponNamePlaceHolder = "Spring Sale";
            objParams.couponCodePlaceHolder = "CXN";
            objParams.selectedDiscount = PERCENTAGE;
            objParams.description = "";
            objParams.dateBegin = currentDate.format('YYYY/MM/DD HH:mm');
            objParams.dateEnd = currentDate.format('YYYY/MM/DD HH:mm');
            objParams.urlImage = null;
        }
        console.log('objParams');
        console.log(objParams);
        objParams.id = 0;
        obj.title = "CREAR CUPON";
        obj.params = objParams;
        console.log(obj);
        sharedNextPage("managecoupons/coupon", obj, history);
    }

    const editElementSelected = (data) => {
        try {
            // console.log("editElementSelected");
            // console.log(data);
            if (data.acciones !== undefined) {
                delete data.acciones;
            }
            if (data.idStatus === INT_STATUS_ACTIVO) {
                alert('No se puede modificar un coupon Activo');
                return;
            }
            let obj = {};
            obj.titulo = "MODIFICAR ABREVIATURA EMPRESA";
            obj.params = data;
            console.log('obj');
            console.log(obj);
            sharedNextPage("managecoupons/coupon", obj, history);
        } catch (err) {
            console.log("editElementSelected err");
            console.log(err);
        }
    };

    const onClickDarBaja = (data) => {
        try {
            if (data.idStatus === INT_STATUS_ACTIVO) {
                alert('No se puede cambiar de estado un coupon activo');
                return;
            }
            console.log("onClickDarBaja");
            console.log(data);
            // setElementoSelected(data);
            // setModalConfirmarDeshabilitarVisible(true);
            setShowModalDarBaja(true);
            setRowSelected(data);
        } catch (err) {
            console.log("onClickDarBaja err");
            console.log(err);
        }
    };

    const onClickRestaurar = (data) => {
        try {
            console.log("onClickRestaurar");
            console.log(data);
            setShowModalRestaurar(true);
            setRowSelected(data);
        } catch (err) {
            console.log("onClickRestaurar err");
            console.log(err);
        }
    };

    const onClickChangeStatus = (data) => {
        try {
            // if (data.idStatus === INT_STATUS_ACTIVO) {
            //     alert('No se puede cambiar de estado un coupon activo');
            //     return;
            // }
            console.log("onClickChangeStatus");
            console.log(data);
            setStatusSelected(null);
            setShowModalStatus(true);
            setRowSelected(data);
        } catch (err) {
            console.log("onClickChangeStatus err");
            console.log(err);
        }
    }

    const onClickhandleCloneCoupon = (data) => {
        try {
            console.log("onClickhandleCloneCoupon");
            console.log(data);
            setRowSelected(data);
            setShowModalClone(true);
        } catch (err) {
            console.log("onClickhandleCloneCoupon err");
            console.log(err);
        }
    }

    const onClickSeeCoupon = (data) => {
        try {
            console.log("onClickSeeCoupon");
            console.log(data);
            setRowSelected(data);
            setShowModalSeeCoupon(true);
        } catch (err) {
            console.log("onClickSeeCoupon err");
            console.log(err);
        }
    }

    const handleConfirmModal = async () => {
        // setModalConfirmarDeshabilitarVisible(false);
        if (rowSelected == null) {
            return;
        }
        let objEnviar = {};
        objEnviar.id = rowSelected.id;
        objEnviar.idStatus = rowSelected.idStatus;
        if (rowSelected.id !== 0) {
            // console.log('crear correo');
            const darBaja = await darBajaCoupon(objEnviar);
            // console.log('creado');
            // console.log(creado);
            if (darBaja === true) {
                alert('Se dio de baja correctamente');
                reset();
            }
            if (darBaja === false) {
                alert('No se pudo dar de baja');
            }
        }
        setShowModalDarBaja(false);
    }

    const reset = () => {
        setCargandoPage(true);
        iniciar();
    }

    const handleConfirmModalChangeStatus = async () => {
        // console.log('handleConfirmModalChangeStatus');
        // console.log(rowSelected);
        if (rowSelected == null) {
            return;
        }
        if (statusSelected === null) {
            alert('Debe seleccionar un nuevo estado');
            return;
        }
        if (rowSelected.idStatus === INT_STATUS_ACTIVO && statusSelected.value === INT_STATUS_DESACTIVADO) {
            if (rowSelected.motivoCambioEstado === undefined || rowSelected.motivoCambioEstado === null ||
                String(rowSelected.motivoCambioEstado).length === 0
            ) {
                alert('Debe ingresar motivo cambio estado');
                return;
            }
        }
        // return;
        let objEnviar = {};
        objEnviar.id = rowSelected.id;
        objEnviar.oldStatus = rowSelected.idStatus;
        objEnviar.idStatus = statusSelected.value;
        objEnviar.motivoCambioEstado = rowSelected.motivoCambioEstado;
        if (rowSelected.id !== 0) {
            // console.log('crear correo');
            const changed = await changeStatusCoupon(objEnviar);
            // console.log('creado');
            // console.log(creado);
            if (changed === true) {
                alert('Se cambio estado correctamente');
                reset();
            }
            if (changed === false) {
                alert('No se pudo cambiar estado');
            }
        }
        setShowModalStatus(false);
    }

    const handleCloneCoupon = async () => {
        try {
            console.log('handleCloneCoupon');
            console.log(rowSelected);
            setShowModalClone(false);
            let objSend = {};
            objSend.color = rowSelected.color;
            objSend.idAbbreviation = rowSelected.idAbbreviation !== undefined && rowSelected.idAbbreviation !== null && String(rowSelected.idAbbreviation).length > 0 ? rowSelected.idAbbreviation : null;
            objSend.idCurrency = rowSelected.idCurrency !== undefined && rowSelected.idCurrency !== null && String(rowSelected.idCurrency).length > 0 ? rowSelected.idCurrency : null;

            objSend.idTypeDiscount = rowSelected.idTypeDiscount;
            objSend.description = rowSelected.description !== undefined && rowSelected.description !== null ? rowSelected.description : "";

            objSend.prefixCode = rowSelected.prefixCode !== undefined && rowSelected.prefixCode !== null && String(rowSelected.prefixCode).length > 0 ? rowSelected.prefixCode : "";
            objSend.numberCoupon = rowSelected.numberCoupon !== undefined && rowSelected.numberCoupon !== null && String(rowSelected.numberCoupon).length > 0 ? rowSelected.numberCoupon : "";
            objSend.codeCoupon = rowSelected.codeCoupon !== undefined && rowSelected.codeCoupon !== null && String(rowSelected.codeCoupon).length > 0 ? rowSelected.codeCoupon : "";
            objSend.nameCoupon = rowSelected.nameCoupon !== undefined && rowSelected.nameCoupon !== null && String(rowSelected.nameCoupon).length > 0 ? rowSelected.nameCoupon : "";
            objSend.valueDiscount = rowSelected.discountValue !== undefined && rowSelected.discountValue !== null && String(rowSelected.discountValue).length > 0 ? rowSelected.discountValue : 0;
            objSend.idAppyDiscountTo = rowSelected.idAppyDiscountTo !== undefined && rowSelected.idAppyDiscountTo !== null && String(rowSelected.idAppyDiscountTo).length > 0 ? rowSelected.idAppyDiscountTo : null;

            objSend.dateBegin = rowSelected.dateBegin;
            objSend.dateEnd = rowSelected.dateEnd;

            objSend.sundayChecked = rowSelected.sundayChecked;
            objSend.mondayChecked = rowSelected.mondayChecked;
            objSend.tuesdayChecked = rowSelected.tuesdayChecked;
            objSend.wednesdayChecked = rowSelected.wednesdayChecked;
            objSend.thursdayChecked = rowSelected.thursdayChecked;
            objSend.fridayChecked = rowSelected.fridayChecked;
            objSend.saturdayChecked = rowSelected.saturdayChecked;

            objSend.limitUse = rowSelected.limitUse;
            objSend.valueLimitUse = rowSelected.valueLimitUse !== undefined && rowSelected.valueLimitUse !== null && String(rowSelected.valueLimitUse).length > 0 ? rowSelected.valueLimitUse : "";
            objSend.limitOnePerUser = rowSelected.limitOnePerUser;

            const cloned = await cloneCoupon(objSend);
            // console.log('created');
            // console.log(created);
            if (cloned === true) {
                alert('Se clono correctamente');
                reset();
            }
            if (cloned === false) {
                alert('No se pudo clonar cupon');
            }
        } catch (err) {
            console.log('handleCloneCoupon err');
            console.log(err);
        }
    }

    const handleBuscarClick = () => {
        reset();
    }

    const handleConfirmRestaurar = async () => {
        // setModalConfirmarDeshabilitarVisible(false);
        if (rowSelected == null) {
            return;
        }
        let objEnviar = {};
        objEnviar.id = rowSelected.id;
        objEnviar.idStatus = rowSelected.idStatus;
        if (rowSelected.id !== 0) {
            // console.log('crear correo');
            const restore = await restaurarCoupon(objEnviar);
            // console.log('creado');
            // console.log(creado);
            if (restore === true) {
                alert('Se restauro correctamente');
                reset();
            }
            if (restore === false) {
                alert('No se pudo restaurar');
            }
        }
        setShowModalRestaurar(false);
    }

    const render = () => {
        try {
            if (cargandoPage === true) {
                return cargandoCard();
            }
            if (tieneAccesoAlModulo === false) {
                return sinAccesoCard();
            }
            return (
                <div>
                    <div className="pt-4 px-4 flex justify-between">
                        <div className="flex justify-center align-center">
                            <label
                                style={{
                                    paddingLeft: 10,
                                    fontWeight: 'bold',
                                }}
                            >
                                GESTIONAR CUPONES
                            </label>
                        </div>
                        <div className="flex justify-center">
                            <button
                                type="button"
                                className={ESTILO_BOTON_LISTAR_NUEVO}
                                onClick={handleNuevoClick}
                                style={{
                                    // display: accesoBotonNuevo,
                                }}
                            >
                                NUEVO
                            </button>
                        </div>
                    </div>
                    <div className="px-4 grid grid-cols-12 gap-4 pt-4">
                        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <Select
                                options={listStatus}
                                isSearchable={true}
                                isClearable={true}
                                value={statusToSearchSelected}
                                onChange={(event) => {
                                    setStatusToSearchSelected(event);
                                }}
                                placeholder="Seleccione Estado"
                            />
                        </div>
                        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <button
                                type="button"
                                className={ESTILO_BOTON_LISTAR_NUEVO}
                                onClick={handleBuscarClick}
                                style={{
                                    // display: accesoBotonNuevo,
                                }}
                            >
                                BUSCAR
                            </button>
                        </div>
                        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                        </div>
                        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 flex justify-end">
                            <button
                                type="button"
                                className={ESTILO_BOTON_LISTAR_NUEVO}
                                onClick={() => { reset(); }}
                                style={{
                                    // display: accesoBotonNuevo,
                                }}
                                title="Actualizar"
                            >
                                <RefreshCcw />
                            </button>
                        </div>
                    </div>
                    <Grid rows={rows} headers={headers} />
                    <ModalConfirmar
                        isOpen={(showModalDarBaja)}
                        onCancel={() => { setShowModalDarBaja(false); }}
                        onConfirm={handleConfirmModal}
                        message="¿Esta seguro de dar baja al Cupon?"
                    />
                    <ModalConfirmar
                        isOpen={showModalRestaurar}
                        onCancel={() => { setShowModalRestaurar(false); }}
                        onConfirm={handleConfirmRestaurar}
                        message="¿Esta seguro de restaurar el Cupon?"
                    />
                    <ModalChangeCouponStus
                        isOpen={showModalStatus}
                        onCancel={() => { setShowModalStatus(false); }}
                        onConfirm={handleConfirmModalChangeStatus}
                        message="Seleccione Nuevo Estado"
                        title={"Cambiar Estado"}
                        listStatus={listStatus}
                        statusSelected={statusSelected}
                        setStatusSelected={setStatusSelected}
                        rowSelected={rowSelected}
                        handleInputChange={handleInputChange}
                    />
                    <ModalConfirmar
                        isOpen={showModalClone}
                        onCancel={() => { setShowModalClone(false); }}
                        onConfirm={handleCloneCoupon}
                        message="¿Esta seguro de clonar Cupon?"
                    />
                    <ModalSeeCoupon
                        isOpen={showModalSeeCoupon}
                        onCancel={() => { setShowModalSeeCoupon(false); }}
                        rowSelected={rowSelected}
                    />
                </div>
            );
        } catch (err) {
            console.log("ManageCoupons render");
            console.log(err);
            return (
                <div className=" w-1/2 m-auto px-[2rem] ">
                    <label>{JSON.stringify(err)}</label>
                </div>
            );
        }
    };

    return render();
}
