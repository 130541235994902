import company from "../../assets/icons/company.png"
import copyright from "../../assets/icons/copyright.png"
import currency from "../../assets/icons/currency.png"
import branch from "../../assets/icons/branch.png"
import companyImage from "../../assets/icons/company-image.png"
import marker from "../../assets/icons/marker.png"

export const SEETINGS_GENERAL_MODULES = [
    {
        id: 1,
        titulo: 'Gestionar Datos Empresa',
        descripcion: 'Datos Empresa',
        icono: company,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'settings/info-company/info-company',
    },
    {
        id: 2,
        titulo: 'Gestionar Abreviatura Empresa',
        descripcion: 'Abreviatura Empresa',
        icono: copyright,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'settings/company-short-name/manage-short-name',
    },
    {
        id: 3,
        titulo: 'Gestionar Moneda',
        descripcion: 'Moneda',
        icono: currency,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'settings/currency/manage-currency',
    },
    {
        id: 3,
        titulo: 'Gestionar Sucursales',
        descripcion: 'Sucursales',
        icono: branch,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'settings/branch/manage-branch',
    },
    {
        id: 4,
        titulo: 'Gestionar Imagenes',
        descripcion: 'Imagenes',
        icono: companyImage,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'settings/images/manage-images',
    },
    {
        id: 5,
        titulo: 'Gestionar Markers',
        descripcion: 'Markers',
        icono: marker,
        color: 'rgba(0, 128, 0, 0.5)',
        href: 'settings/markers/manage-markers',
    },

];