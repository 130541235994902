import moment from "moment/moment";
import { POST, PUT, STRING_FAILURE, STRING_SUCCESS } from "../utils/const/http-const";
import { getHeaders } from "./utils";
import { getBaseUrl } from "./env/env";
import "moment-timezone";

export async function getCountCoupons() {
    try {
        let count = "";
        const { code, value } = await fetch(
            getBaseUrl() + "/coupon/get-count-coupons/",
            {
                headers: getHeaders(),
                method: POST,
                // body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        if (code === STRING_SUCCESS) {
            count = value + 1;
        }
        return count;
    } catch (err) {
        console.log("getCountCoupons err");
        console.log(err);
        return "";
    }
}

export async function getListCoupons(obj, combo = false) {
    try {
        let rows = [];
        const { code, value } = await fetch(
            getBaseUrl() + "/coupon/get-list-coupon/",
            {
                headers: getHeaders(),
                method: POST,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        if (code === STRING_SUCCESS) {
            if (combo === true) {
                for (let i = 0; i < value.length; i++) {
                    const element = value[i];
                    let obj = {};
                    obj.value = element.id;
                    obj.label = element.abbreviation;
                    rows.push(obj);
                }
            }
            if (combo === false) {
                for (let i = 0; i < value.length; i++) {
                    value[i].fullCodeCoupon = value[i].prefixCode + value[i].numberCoupon + value[i].codeCoupon;
                    value[i].dateBegin = moment.utc(value[i].dateBegin).format('YYYY/MM/DD HH:mm');
                    value[i].dateEnd = moment.utc(value[i].dateEnd).format('YYYY/MM/DD HH:mm');
                    value[i].dateBeginLabel = moment.utc(value[i].dateBegin).format('DD/MM/YYYY HH:mm');
                    value[i].dateEndLabel = moment.utc(value[i].dateEnd).format('DD/MM/YYYY HH:mm');
                    value[i].sundayLabel = value[i].sundayChecked === 1 ? "SI" : "NO";
                    value[i].mondayLabel = value[i].mondayChecked === 1 ? "SI" : "NO";
                    value[i].tuesdayLabel = value[i].tuesdayChecked === 1 ? "SI" : "NO";
                    value[i].wednesdayLabel = value[i].wednesdayChecked === 1 ? "SI" : "NO";
                    value[i].thursdayLabel = value[i].thursdayChecked === 1 ? "SI" : "NO";
                    value[i].fridayLabel = value[i].fridayChecked === 1 ? "SI" : "NO";
                    value[i].saturdayLabel = value[i].saturdayChecked === 1 ? "SI" : "NO";
                    value[i].limitUseLabel = value[i].limitUse === 1 ? "SI" : "NO";
                    value[i].limitOnePerUserLabel = value[i].limitOnePerUser === 1 ? "SI" : "NO";

                    value[i].validoParaSucursales = value[i].validoParaSucursales;
                    value[i].validoParaSucursalesLabel = value[i].validoParaSucursalesLabel === 1 ? "SI" : "NO";

                    value[i].dateUpdated = moment(value[i].dateUpdated).format('DD/MM/YYYY HH:mm');
                    rows.push(value[i]);
                }
            }
        }
        return rows;
    } catch (err) {
        console.log("getListCoupons err");
        console.log(err);
        return [];
    }
}

export async function createCoupon(obj) {
    try {
        let creado = false;
        const timezone = moment.tz.guess();
        const offset = moment().tz(timezone).format('Z');
        obj.timezone = timezone;
        obj.offset = offset;
        const { code, message } = await fetch(
            getBaseUrl() + "/coupon/save-coupon/",
            {
                headers: getHeaders(),
                method: POST,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === STRING_SUCCESS) {
            creado = true;
        }
        if (code === STRING_FAILURE) {
            alert(message);
        }
        return creado;
    } catch (err) {
        console.log("createCoupon err");
        console.log(err);
        return false;
    }
}

export async function editCoupon(obj) {
    try {
        let modified = false;
        const { code, message } = await fetch(
            getBaseUrl() + "/coupon/edit-coupon/",
            {
                headers: getHeaders(),
                method: PUT,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === STRING_SUCCESS) {
            modified = true;
        }
        if (code === STRING_FAILURE) {
            alert(message);
        }
        return modified;
    } catch (err) {
        console.log("editCoupon err");
        console.log(err);
        return false;
    }
}

export async function darBajaCoupon(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            getBaseUrl() + "/coupon/dar-baja-coupon/",
            {
                headers: getHeaders(),
                method: PUT,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === STRING_SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("darBajaCoupon err");
        console.log(err);
        return false;
    }
}

export async function changeStatusCoupon(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            getBaseUrl() + "/coupon/change-status-coupon/",
            {
                headers: getHeaders(),
                method: PUT,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === STRING_SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("changeStatusCoupon err");
        console.log(err);
        return false;
    }
}

export async function saveDraftCoupon(obj) {
    try {
        let modified = false;
        const timezone = moment.tz.guess();
        const offset = moment().tz(timezone).format('Z');
        obj.timezone = timezone;
        obj.offset = offset;
        const { code, message } = await fetch(
            getBaseUrl() + "/coupon/save-draft-coupon/",
            {
                headers: getHeaders(),
                method: POST,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === STRING_SUCCESS) {
            modified = true;
        }
        if (code === STRING_FAILURE) {
            alert(message);
        }
        return modified;
    } catch (err) {
        console.log("saveDraftCoupon err");
        console.log(err);
        return false;
    }
}

export async function editDraftCoupon(obj) {
    try {
        let modified = false;
        const { code, message } = await fetch(
            getBaseUrl() + "/coupon/edit-draft-coupon/",
            {
                headers: getHeaders(),
                method: PUT,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === STRING_SUCCESS) {
            modified = true;
        }
        if (code === STRING_FAILURE) {
            alert(message);
        }
        return modified;
    } catch (err) {
        console.log("editDraftCoupon err");
        console.log(err);
        return false;
    }
}

export async function cloneCoupon(obj) {
    try {
        let modified = false;
        const timezone = moment.tz.guess();
        const offset = moment().tz(timezone).format('Z');
        obj.timezone = timezone;
        obj.offset = offset;
        const { code, message } = await fetch(
            getBaseUrl() + "/coupon/clone-coupon/",
            {
                headers: getHeaders(),
                method: POST,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === STRING_SUCCESS) {
            modified = true;
        }
        if (code === STRING_FAILURE) {
            alert(message);
        }
        return modified;
    } catch (err) {
        console.log("cloneCoupon err");
        console.log(err);
        return false;
    }
}

export async function restaurarCoupon(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            getBaseUrl() + "/coupon/restaurar-coupon/",
            {
                headers: getHeaders(),
                method: PUT,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === STRING_SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("restaurarCoupon err");
        console.log(err);
        return false;
    }
}

export async function existeCoupon(obj) {
    try {
        let result = true;
        const { code, value } = await fetch(
            getBaseUrl() + "/coupon/existe-coupon/",
            {
                headers: getHeaders(),
                method: POST,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === STRING_SUCCESS) {
            result = value;
        }
        return result === 0 ? false : true;
    } catch (err) {
        console.log("existeCoupon err");
        console.log(err);
        return true;
    }
}

export async function saveImageCoupon(obj) {
    try {
        let result = { error: true };
        const { code, value } = await fetch(
            getBaseUrl() + "/upload-image/upload-image/",
            {
                headers: getHeaders(),
                method: POST,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('value');
        // console.log(value);
        if (code === STRING_SUCCESS) {
            result.error = false;
            result.value = value;
        }
        return result;
    } catch (err) {
        console.log("saveImageCoupon err");
        console.log(err);
        return { error: true };
    }
}